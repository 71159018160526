import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { IAMAppService } from '../iam-app/iam-app.service';

import firebase from 'firebase/compat/app';
import { firstValueFrom, Observable } from 'rxjs';
import { User } from '../../models/user.model';
import { AlertService } from 'src/app/services/alert/alert.service';

export enum LOGINMETHOD {
  ID_PASSWORD = 0,
  GOOGLE = 1,
  FACEBOOK = 2
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  get user$(): Observable<firebase.User | null> { return this.firebaseAuth.user }

  constructor(
    //     // private store: AngularFirestore,
    public firebaseAuth: AngularFireAuth,  //firebase.auth()?
    private iamAppService: IAMAppService,
    private alert: AlertService
  ) { }

  //Login to edudate-app to fetch authorization map
  appLogin(logUser: User) {
    //JWT interceptro will add the token
    this.iamAppService.login(logUser)
      .subscribe((ss: any) => {  //SS Simple Status indicates success!
        console.log("Login result ", JSON.stringify(ss, null, 2));
        // user.claims[sec_permissions]=User Access Map
      });
  }
  private loginWithEmailAndPassword(user: User) {
    if (user.email == null || user.email === undefined) user.email = "";
    if (user.password == null || user.password === undefined) user.password = "";
    return this.firebaseAuth.signInWithEmailAndPassword(user.email, user.password)
      .then((userCredential) => {
        //set permissions in user custom claims
        user.uid = userCredential.user!.uid;
        user.loginMethod = LOGINMETHOD.ID_PASSWORD;
        this.appLogin(user); //if exception raises it quits the pipeline
        throw new Error("Login failed --TEST--");
        return userCredential;
      })
    // propagate error to the calling component
    // .catch((error) => {
    //   console.error("AuthService-" + error);
    //   if (this.alert == null  ) console.log("Alert is  null")
    //   this.alert.error("Login failed: " + error);
    //   // ..
    // });

  }

  login(loginUser: User): Promise<firebase.auth.UserCredential | void> {
    console.log("Login user:", loginUser)
    if (!loginUser.email) { //fetch email id from user profile in the IAM server
      return firstValueFrom(this.iamAppService.getUserByUserName((loginUser.name) ? loginUser.name : ""))
        .then((user_profile: User) => {
          loginUser.email = user_profile.email;
          return this.loginWithEmailAndPassword(loginUser);
        })
    } else {
      return this.loginWithEmailAndPassword(loginUser);
    }
  }

  logout() {
    return this.firebaseAuth.signOut()
      .then(() => {
        //console.log("Logged out successfully");
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        //    this.auth.updateCurrentUser( null);
      })
      // ,
      //   (err) => console.error("Got error logging out " + JSON.stringify(err)))
  }

  loginGoogle() {
    let provider = new firebase.auth.GoogleAuthProvider();
    // provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    // provider.setCustomParameters({
    //   'login_hint': 'user@example.com'
    // });
    return this.firebaseAuth.signInWithPopup(provider)
      .then((userCredential) => {
        console.log(userCredential)
        let loginUser = new User();
        loginUser.loginMethod = LOGINMETHOD.GOOGLE;
        loginUser.email = userCredential.user?.email
        loginUser.name = userCredential.user?.displayName;
        loginUser.pictureUrl = userCredential.user?.photoURL;
        loginUser.uid = userCredential.user?.uid;
        loginUser.displayName = userCredential.user?.displayName;
        console.log("Google USer:", loginUser)
        this.appLogin(loginUser);
      })

  }

  sendPasswordResetEmail(email: string): Promise<void> {
    return this.firebaseAuth.sendPasswordResetEmail(email);
  }

  register(registerUser: User): Promise<User> {
    if (registerUser.email == null || registerUser.email === undefined) registerUser.email = "";
    if (registerUser.password == null || registerUser.password === undefined) registerUser.password = "";
    return this.firebaseAuth.createUserWithEmailAndPassword(registerUser.email, registerUser.password)
      .then(userCredential => {
        //console.log("Registered.");
        registerUser.updateFBUser(userCredential.user);
        this.appLogin(registerUser);
        return registerUser;
      })
  }
}
